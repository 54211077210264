import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/components/domains/authentication/LoginView.vue";
import DefaultViewLayout from "@/components/infrastructure/layouts/DefaultViewLayout.vue";
import AboutView from "@/components/domains/about/AboutView.vue";
import AudioPlayerDemo from "@/components/domains/demo/AudioPlayerDemo.vue";
import LoggedInViewLayout from "@/components/infrastructure/layouts/LoggedInViewLayout.vue";
import AirUmxSectionView from "@/components/domains/rooms/AirUmxSectionView.vue";
import HomeView from "@/components/domains/home/HomeView.vue";
import AirUmxRoomsView from "@/components/domains/rooms/AirUmxRoomsView.vue";
import EasilyLoginView from "@/components/domains/authentication/easily/EasilyLoginView.vue";
import EasilyLoginForwardingView from "@/components/domains/authentication/easily/EasilyLoginForwardingView.vue";
import NewHomeView from "@/components/domains/home/NewHomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    // deprecated V1
    path: "/",
    component: DefaultViewLayout,
    children: [
      {
        path: "",
        name: "home",
        component: NewHomeView
      },
      {
        path: "old-login",
        name: "OldLogin",
        component: HomeView
      },
      // {
      //   path: "about",
      //   name: "about",
      //   component: AboutView
      // },
      {
        path: "easily-login",
        name: "EasilyLogin",
        component: EasilyLoginForwardingView
      },
      {
        path: "demo",
        name: "demo",
        component: AudioPlayerDemo
      },
    ]
  },
  {
    // deprecated V1
    path: "/mypage",
    component: LoggedInViewLayout,
    children: [
      {
        path: '',
        name: 'AirUmxSection',
        component: AirUmxRoomsView
      },
      {
        path: 'rooms',
        name: 'AirUmxSection',
        component: AirUmxRoomsView
      },
      {
        path: 'room/section/:id',
        name: 'AirUmxSection',
        component: AirUmxSectionView
      },
      {
        path: 'easily-login',
        name: 'EasilyLogin',
        component: EasilyLoginView
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

