import { render, staticRenderFns } from "./NewHomeView.vue?vue&type=template&id=22521e07&scoped=true&"
import script from "./NewHomeView.vue?vue&type=script&lang=ts&"
export * from "./NewHomeView.vue?vue&type=script&lang=ts&"
import style0 from "./NewHomeView.vue?vue&type=style&index=0&id=22521e07&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22521e07",
  null
  
)

export default component.exports