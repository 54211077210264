
import { Vue, Component } from "vue-property-decorator";

@Component
export default class NewHomeView extends Vue {
  private ticking = false;
  private showBanner = true;
  private observer: IntersectionObserver | null = null;

  private handleScroll = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        const bg = this.$refs.parallaxBackground as HTMLElement;
        if (bg) {
          const offset = window.pageYOffset;
          bg.style.transform = `translateY(${offset * 0.3}px)`;
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  };

  mounted() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });

    const productSection = this.$refs.productSection as HTMLElement;
    if (productSection) {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.showBanner = false;
            } else {
              this.showBanner = true;
            }
          });
        },
        {
          root: null,
          threshold: 0,
        }
      );
      this.observer.observe(productSection);
    }
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.observer && this.$refs.productSection) {
      this.observer.unobserve(this.$refs.productSection as HTMLElement);
      this.observer.disconnect();
    }
  }
}
