
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CommonViewLayout",
})
export default class CommonViewLayout extends Vue {
  @Prop({ type: Array, default: () => [] })
  navLinks!: Array<{ path: string; title: string }>;

  mounted() {
    window.scrollTo(0, 0);
  }
}
